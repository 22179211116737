<template>
  <div class="text-center">
    <p class="salutation">{{ name }} let's setup your video call</p>
    <p class="mt-1 bold">
      Please wait while we check your settings and see if everything’s fine
    </p>
    <v-row justify="center">
      <v-col sm="12" cols="12" md="10" xl="8" lg="10">
        <div class="feedback">
          <v-overlay
            v-show="showFixSettings"
            :absolute="true"
            opacity="0"
            max-width="500"
          >
            <v-row
              class="ma-0 pa-3"
              style="background-color: #f9f9f9"
              justify="center"
              align="center"
            >
              <v-col align="left" style="width: 100%; position: relative">
                <v-col cols="col-12" class="pa-0">
                  <v-row style="justify-content: space-between">
                    <p class="mb-2" style="color: #191919; font-size: 22px">
                      How to fix settings?
                    </p>
                    <span
                      style="
                        display: block;
                        text-decoration: underline;
                        color: #191919;
                        cursor: pointer;
                      "
                      @click="showFixSettings = false"
                    >
                      Close
                    </span>
                  </v-row>
                </v-col>
                <v-divider class="mb-3" light></v-divider>
                <v-col class="pa-0" cols="col-12">
                  <p
                    class="mb-3"
                    style="font-weight: 600; font-size: 15px; color: #191919"
                  >
                    How do I enable camera, microphone and location services?
                  </p>
                </v-col>
                <v-col class="pa-0" cols="col-12">
                  <p
                    class="mb-2"
                    style="font-weight: 300; font-size: 15px; color: #191919"
                  >
                    1. Click on the
                    <span
                      ><img
                        src="@/assets/password.svg"
                        alt="lock"
                        style="width: 12px"
                    /></span>
                    icon located next to the website URL on the address bar.
                  </p>
                  <v-col class="pa-0 pl-2" cols="col-12">
                    <figure>
                      <img
                        src="@/assets/ssSetting.png"
                        alt="lock"
                        style="width: 150px"
                      />
                    </figure>
                    <p
                      class="mb-4"
                      style="font-weight: 300; font-size: 12px; color: #191919"
                    >
                      a. Click on <strong>Site settings </strong> if using an
                      Android device or <strong> Website settings </strong> for
                      Safari in iOS .
                    </p>
                  </v-col>
                </v-col>
                <v-col class="pa-0" cols="col-12">
                  <p
                    class="mb-4"
                    style="font-weight: 300; font-size: 15px; color: #191919"
                  >
                    2. For each of the fields
                    <strong>Camera, Microphone </strong> and
                    <strong> Location </strong> , open the dropdown and select
                    Allow.
                  </p>
                </v-col>
                <v-col class="pa-0" cols="col-12">
                  <p
                    class="mb-1"
                    style="font-weight: 300; font-size: 15px; color: #191919"
                  >
                    3. The browser will ask you to reload the page for the
                    changes to reflect.
                  </p>
                </v-col>
              </v-col>
            </v-row>
          </v-overlay>
          <video id="reflection" autoplay playsinline></video>
          <div id="videoGradient" class="videoGrad"></div>
          <div id="permissionBox" class="permissionProgress">
            <p
            v-bind:class= "['instructionWrapperError', { 'instructionWrapperSuccess' : instructionWrapper.status==='success'  }]"

            >{{ instructionWrapper.text }}</p>
            <div class="progress pa-2 pb-2">
              <span
                v-bind:color="isVideoEnabled === true ? 'success' : 'error'"
                icon
                outlined
                fab
                class="ml-6 mr-6"
              >
                <v-icon small color="white">fas fa-video</v-icon>
              </span>
              <span
                v-bind:color="isAudioEnabled === true ? 'success' : 'error'"
                icon
                outlined
                fab
               class="ml-6 mr-6"
              >
                <v-icon small color="white">fas fa-microphone</v-icon>
              </span>
              <span
                v-bind:color="isNetworkFast === true ? 'success' : 'error'"
                icon
                outlined
                fab
                class="ml-6 mr-6"
              >
                <v-icon small color="white">fas fa-wifi</v-icon>
              </span>
              <span
                v-bind:color="isNetworkGood === true ? 'success' : 'error'"
                icon
                outlined
                fab
               class="ml-6 mr-6"
              >
                <v-icon small color="white">fas fa-globe-asia</v-icon>
              </span>
              <span
                v-bind:color="isGeolocation === true ? 'success' : 'error'"
                icon
                outlined
                fab
               class="ml-6 mr-6"
              >
                <v-icon small color="white">fas fa-location-arrow</v-icon>
              </span>
            </div>
            <div v-if="fixSettings" class="px-1 py-2">
              <v-btn
                small
                dark
                outlined
                color="rgba(255, 255, 255, 1)"
                @click="showFixSettings = true"
                >
                How to fix settings?
                </v-btn>
            </div>
            <v-progress-linear
              class="mt-2"
              background-opacity="0.3"
              buffer-value="100"
              height="4"
              :rounded="true"
              :value="this.currentStep * 20"
              color="light-blue"
            ></v-progress-linear>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="text-center mt-3 mb-5">
      <v-btn
        v-if="isAllowedToMove"
        @click="goodToGo()"
        class="customBtn"
        dark
        :style="{
          backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1365c0'}`,
          color:`${$store.getters.callConfig.buttonTextColor || null}`
        }"
        >Get Started</v-btn
      >
      <v-btn
        v-if="isFailure"
        @click="startProcess(false)"
        class="customBtn"
        dark
        color="warning"
        >Retry</v-btn
      >
    </div>
    <v-dialog v-model="isDesktopEmulation" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center pa-5">
          <img src="@/assets/mobileDevice.svg" />
          <p class="title mt-3">
            Looks like you’re viewing this website as desktop mode from your mobile device.
          </p>
        </v-card-text>
        <v-card-text>
          <p class="body-2">
            For the best experience, please switch to Mobile Mode.
          </p>
          <p class="subtitle-2">
            Follow these steps: <br>
            1. Open your browser settings.<br>
            2. Disable Desktop Mode (Request Mobile Site).<br>
            3. Click the "Ignore" button below to continue, if you have already done it.<br>
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 mr-2 pb-3">
          <v-spacer></v-spacer>
          <v-btn small dark color="signzy_blue" @click="initiateService(true)">
            Ignore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="vpnCheckFailed" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center pa-5">
          <img src="@/assets/vpnCheckDialog.svg" />
          <p class="title mt-3">
            Looks like you’re using a VPN / Proxy Server.
          </p>
        </v-card-text>
        <v-card-text>
          <p class="body-2">
            We understand your concerns for privacy and security. But the use of
            a VPN / Proxy server is not compliant with the Video KYC guidelines.
          </p>
          <p class="subtitle-2">
            Follow these steps: <br>
            1. Stay on this page without refreshing.<br>
            2. Proceed to disconnect your VPN now.<br>
            3. After successfully disconnecting, please return to this page.<br>
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 mr-2 pb-3">
          <v-spacer></v-spacer>
          <template v-if="isCheckingVPN">
            <v-progress-circular indeterminate color="signzy_blue"></v-progress-circular>
            Checking
          </template>
          <v-btn v-else small dark color="signzy_blue" @click="checkVPNRoute()">
            Try again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="notAllowedCountry" persistent max-width="550">
      <v-card v-if=countryNameAvailable>
        <v-card-text class="text-center pa-5">
          <img src="@/assets/vpnCheckDialog.svg" />
          <p class="title mt-3">Looks like you’re from {{ countryName }}!</p>
          <p class="subtitle-2">
            You cannot perform Video KYC in {{ countryName }}.
          </p>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-text class="text-center pa-5">
          <img v-if="vpnCheckCounter < 3" src="@/assets/error.svg" />
          <img v-else  src="@/assets/error12.svg" />
          <p v-if="vpnCheckCounter < 3" class="title mt-3 signzy_blue--text" >
            We are unable to detect your location
          </p>
          <p v-else class="title mt-3 " style="color:#D33553" >
            We’re unable to get your location, kindly try again with a different network/device or start again
          </p>
          <p v-if="vpnCheckCounter < 3" class="subtitle-2 black--text" >
            <strong>Kindly disable any VPN you are connected and retry.
            </strong>
          </p>
        </v-card-text>
        <v-card-actions class="pt-0 mr-2 pb-3 justify-center">
          <v-btn v-if="vpnCheckCounter < 3" primary dark color="signzy_blue" @click="checkVPNRoute()" style="text-transform: none !important;"
            >Retry</v-btn
          >
          <v-btn v-else primary dark color="signzy_blue" @click="routeBack()" style="text-transform: none !important;"
            >Start Again</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { vcip_end_points, constants } from "@/config";
import { calculateSpeed } from "@/Plugins/internetSpeed.js";
import { getPosition, delay } from "@/Plugins/utils";
import {logReportData}  from  "@/Plugins/reportLogger.js";
import { mapActions } from "vuex";
import {encrypt,decrypt} from '../../flux/encdec.flux'
import { userDataStore } from "@/Plugins/UserData.Store";
import { endCustomer } from "@/assets/subStatus.json";

import axiosLib from "axios";
export default {
  data: () => ({
    name: "",
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    checkbox: false,
    netSpeedThreshold: 200,
    audioStream: false,
    videoStream: false,
    isVideoEnabled: false,
    isGeolocation: false,
    isAudioEnabled: false,
    isNetworkGood: false,
    isNetworkFast: false,
    instructions: "",
    currentStep: 0,
    isFailure: false,
    geoCountryCheckPass: true,
    vpnCheckFailed: false,
    notAllowedCountry: false,
    countryWhitelisting: false,
    countryName: "Other Country",
    allowedCountries: [],
    instructionWrapper:{status:"error",text:"Can't access your camera, please allow from settings"},
    fixSettings: false,
    showFixSettings: false,
    countryNameAvailable: false,
    vpnCheckCounter: 0,
    isMultipleSession: false,
    logObject: endCustomer,
    isVPNDisconnected: true,
    isCheckingVPN: false,
    isDesktopEmulation: false
  }),
  beforeRouteEnter(to,from,next) {
    if(sessionStorage.getItem("onPage") == "Prerequisites" && !sessionStorage.getItem("internalRefresh")){
      sessionStorage.setItem("PrerequisitesRefreshed", true);
      sessionStorage.setItem("triggerCustomMIS", true);
      sessionStorage.setItem("fromPage", "Prerequisites");
    }

    if(sessionStorage.getItem("disableInstructionsPage")){
      sessionStorage.getItem("internalRefresh") && sessionStorage.removeItem("internalRefresh");
      sessionStorage.getItem("adminLeft") && sessionStorage.removeItem("adminLeft")
      let path = from.path.toString().split('/').pop();
      if((path == "start" && !sessionStorage.getItem("StartVideoRefreshed")) || 
      (path == "initiate" && !sessionStorage.getItem("InitiateQueueRefreshed")) || 
      ((path == "networkTooSlow" || path == "abrupt" || path == "noStream" || path == "ReDrop" ||
        path == "heartbeatFailed") && !sessionStorage.getItem("InitiateQueueRefreshed"))){
          if((path == "initiate" && !sessionStorage.getItem("InitiateQueueRefreshed"))){
            sessionStorage.setItem("customInitiatePageCleanup", true);
          }
          sessionStorage.setItem("internalRefresh", true);
          next();
          let timeout = setTimeout(()=>{
            clearInterval(timeout);
            location.reload();
          },50);
      } else {
        next();
      }
    } else {
      next();
    }
  },
  beforeRouteLeave (to, from, next) {
    if(!this.$store.getters.allowUserInQueue && !sessionStorage.getItem("PrerequisitesRefreshed")){
      this.isMultipleSession && next();
      return false;
    } else{
      this.$store.dispatch("pageExitCleanUp", "Prerequisites");
      next();
    }
  },
  beforeCreate(){
    if(sessionStorage.getItem("PrerequisitesRefreshed") && !sessionStorage.getItem("disableInstructionsPage")){
      this.$router.replace({ name: 'instructions' });
    }
  },
  created() {
    window.onbeforeunload = () => {
      try {
        let initiationId = sessionStorage.getItem("initiationId");
        let data = { ...this.logObject.prerequisitePage.closed };
        data.currentProcess = "Customer closed the prerequisite Page";
        data.isCallActive = false;
        data.callId = this.requestId;
        logReportData(this.socket, initiationId, data); 
      } catch (err) {
        console.log(err);
      }
    }
    if(!sessionStorage.getItem("internalRefresh")){
      this.pageEntry("Prerequisites");
    }

    if (this.callData && this.callData.configuration) {
      this.instructions = this.callData.configuration.instructionHeader || "";
      this.countryWhitelisting =
        this.callData.configuration.countryWhitelisting || false;
      this.allowedCountries =
        this.callData.configuration.allowedCountries || [];
      this.$store.commit('setDisableVPNCheck', (this.callData.configuration.disableVPNCheck || false));
      this.$store.commit('setEnableIpInfo', (this.callData.configuration.enableIpInfo || false));
    }
    this.netSpeedThreshold = this.callData.configuration.minSpeedRequired || 200; // default 200
    if (
      this.callData &&
      this.callData.users &&
      this.callData.users[0] &&
      this.callData.users[0].name
    ) {
      this.name = `${this.callData.users[0].name}, `;
      if (this.name) {
        this.name =
          this.name[0].toUpperCase() + this.name.substr(1).toLowerCase();
        this.name = this.name.split(" ")[0];
      }
    }

    if(sessionStorage.getItem("disableInstructionsPage") && !sessionStorage.getItem("internalRefresh")){
      this.sessionCleanUp();
    }

    if(sessionStorage.getItem("disableInstructionsPage") && !sessionStorage.getItem("backFromReschedule")){
      //IMPORTANT: Adding these events since we are skipping the instructions page with the above flag
      eventBus.$on("landing-page-mis-triggered", ()=>{
        this.initiateService();
        try{
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {}; 
          data.onPage = "Prerequisites"
          data.currentProcess = "User was on prerequisites page";
          data.isCallActive = true;
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
          // eventBus.$off("landing-page-mis-triggered");
          if(!sessionStorage.getItem("internalRefresh") && sessionStorage.getItem("customInitiatePageCleanup")){
            this.customInitiatePageCleanup();
          }
          eventBus.$emit("prev-session-logging-completed");
        } catch (error){
          console.log(error);
        }
      });

      eventBus.$on("multiple-session-detected", ()=>{
        //Turning off the existing eventBus since multiple session is triggered before the previous event
        eventBus.$off("landing-page-mis-triggered");
        eventBus.$on("landing-page-mis-triggered", ()=>{
          // eventBus.$off("landing-page-mis-triggered");
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.multipleSessions.dropped };
            // data.onPage = "Landing";
            data.callId = this.requestId;
            data.isMultipleSession = true;
            data.currentProcess = "Multiple sessions detected";
            logReportData(this.socket, initiationId, data);
            this.isMultipleSession = true;
            this.$router.replace({ path: "/error/multipleSession" });
          }        
          catch(err){
            console.log("Error type:: socket reporting :::", err);
          }
        })
      });
    } else {
      sessionStorage.getItem("backFromReschedule") && sessionStorage.removeItem("backFromReschedule");
      this.initiateService();
    }

    //Commenting this MIS logging, since it's overwritting older initiation Id
    // try{
    //   let initiationId = sessionStorage.getItem('initiationId');
    //   let data = {};
    //   data.onPage = "Prerequisites"
    //   data.currentProcess = "User is on Prerequisites page";
    //   data.callId = this.requestId ? this.requestId : "";
    //   logReportData(this.socket,initiationId,data);
    // } catch (error){
    //   console.log(error);
    // }

    // setting this in case end user clicks back button on startvideo
    this.$store.commit("setBackButtonState",false) // this is added t o avoid the call dequeue on backbutton from start video page
    if(!this.$store.getters.disableVPNCheck){
      this.checkVPNConnectionRepeatedly();
    }
  },
  methods: {
    ...mapActions(["pageEntry", "sessionCleanUp", "customInitiatePageCleanup"]),
    async goodToGo() {
      await this.checkForVPN();
      if(!this.vpnCheckFailed){
        try{
          this.videoStream.getTracks().forEach((track) => {
          //removed track before stoping from media stream object to resolve freezing chrome on android 11
            this.videoStream.removeTrack(track)
            track.stop();
          });
          this.audioStream.getTracks().forEach((track)=> {
            //removed track before stoping from media stream object to resolve freezing chrome on android 11
                  this.audioStream.removeTrack(track)
            track.stop();
          });
        }
        catch(error){
          console.log("Error removing video and audio track",error)
        }

        let video = document.getElementById("reflection");
        this.$store.commit("setEndUserData", ["videoWidth", video.offsetWidth]);
        this.$store.commit("setEndUserData", ["videoHeight", video.offsetHeight]);
        if (this.sessionId) {
          this.$store.commit("setInitiated", true);
          this.$router.push({
            name: "conferenceVideo",
          });
        } else {
            this.$router.push({ name: "initiateQueue" });
        }
      }
    },
    toTitleCase(str) {
        return str.replace(/\w\S*/g, function(txt){
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    checkVPNRoute() {
      this.vpnCheckCounter++;
      if(this.currentStep != 3) {
        this.goodToGo();
      } else {
        this.checkForVPN();
      }
    },
    async getLocationData(data) {
      try{
        let resp = await axiosInstance.post(vcip_end_points.location,data);
          console.log("allowedCountries",this.allowedCountries);
          const ipData = await axiosLib.get(this.$store.getters.envValues?.ipFinder || vcip_end_points.ipFinder);
          
          if(!ipData.data){
            throw new Error("Forencis error")
          }
          let forensicsData;
          const forensicsCheckExists=!this.$store.getters.disableVPNCheck || this.$store.getters.enableIpInfo;
          if (forensicsCheckExists) {
            const encryptedIPAddress = encrypt(ipData.data.ip_addr, this.$store.getters.envValues.otpEncKey);

            const encryForensicsData = (await axiosLib.post(vcip_end_points.forensics, { ip: encryptedIPAddress, checkProbe: !this.$store.getters.disableVPNCheck, checkInfo: this.$store.getters.enableIpInfo }));

            const decryptedData = decrypt(encryForensicsData.data, this.$store.getters.envValues.otpEncKey);

            try {
              forensicsData = JSON.parse(decryptedData);
              userDataStore.commit("setForensicsDetails", forensicsData?.data);
            } catch (err) {
              console.error("forensics JSON error: ", err)
            }
          }

          if(!this.allowedCountries.includes(resp.data.shortName) || (forensicsCheckExists && forensicsData?.data?.country_code && !this.allowedCountries.includes(forensicsData?.data?.country_code))){
              if(resp.data.countryName){
                this.countryNameAvailable = true;
                this.geoCountryCheckPass= false;
                this.countryName = forensicsData?.data?.country_name ?? resp?.data?.countryName;
                this.notAllowedCountry = true;
              }else {
                eventBus.$emit("vueSnack", "Something went wrong! Could not fetch your Country");
                throw "Could not fetch country name";
              }
          }else{
            this.geoCountryCheckPass= true;
          }
      }catch(err){
        console.log("Something went wrong in fetching location for geo whitelisting::",err);
        throw "Geo-location API failed"
      }
    },
    routeBack(){
      this.$router.replace({name:"instructions"})
    },
    initiateService(bypassEmulationCheck = false){
      if(!bypassEmulationCheck && window.innerWidth > screen.width) {
        this.isDesktopEmulation = true;
        try{
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {};
          data.onPage = "Prerequisites"
          data.currentProcess = "Desktop emulation detected on a mobile device"
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
        } catch (error){
          console.log(error);
        }
      } else {
        setTimeout(() => {
          if(bypassEmulationCheck){
            try{
              let initiationId = sessionStorage.getItem('initiationId');
              let data = {};
              data.onPage = "Prerequisites"
              data.currentProcess = "User has ignored the popup for desktop emulation"
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            } catch (error){
              console.log(error);
            }
          }
          this.isDesktopEmulation = false;
          let video = document.getElementById("reflection");
          let permissionBox = document.getElementById("permissionBox");
          permissionBox.style.width = `${video.offsetWidth}px`;
          permissionBox.style.position = "absolute";
          let videoGradient = document.getElementById("videoGradient");
          videoGradient.style.width = `${video.offsetWidth}px`;
          videoGradient.style.height = `${video.offsetHeight}px`;
          videoGradient.style.position = "absolute";

          //Setting videoWidth and height of EndUser
          this.startProcess(true);
        }, 50);
      }
    },
    startProcess(status) {
      setTimeout(() => {
        this.isFailure = false;
        switch (this.currentStep) {
          case 0:
            this.fixSettings = false;
            this.showVideoStream();
            break;
          case 1:
            this.fixSettings = false;
            this.showAudioStream();
            break;
          case 2:
            this.checkForInternetSpeed();
            break;
          case 3:
            this.checkForVPN();
            break;
          case 4:
            this.fixSettings = false;
            if(!status){
                let uAgent =  navigator.userAgent;
                //to tackle safari location retry bug
                if(uAgent.includes("iPhone") || uAgent.includes("iPad")){
                  location.reload();
                }
            }
            this.captureGeoLocation();
        }
      }, 400);
    },
    showVideoStream() {
      this.instructionWrapper.text = "Can't access your camera, please allow from settings"
      let constraints = {
        audio: false,
        video: {
          facingMode: "user",
        },
      };
      let video = document.getElementById("reflection");
      // this.isVideoEnabled = true;
      // this.showAudioStream();
      // this.currentStep = 1;
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          video.srcObject = stream;
          this.isVideoEnabled = true;
          this.videoStream = stream;
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {};
            data.onPage = "Prerequisites"
            data.currentProcess = "Granted permission for video stream"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch (error){
            console.log(error);
          }
          this.showAudioStream();
          this.currentStep = 1;
        })
        .catch((err) => {
          this.fixSettings = true;
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.prerequisitePage.cameraPermissionFailed };
            data.onPage = "Prerequisites"
            data.currentProcess = "Customer failed to provide camera access permission"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch (error){
            console.log(error);
          }
          console.log(err);
          this.isFailure = true;
          this.isVideoEnabled = false;
        });
    },
    showAudioStream() {
      this.instructionWrapper.text = "Can't access your microphone, please allow from settings.";
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          this.isAudioEnabled = true;
          this.audioStream = stream;
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {};
            data.onPage = "Prerequisites"
            data.currentProcess = "Granted permission for audio stream"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch (error){
            console.log(error);
          }
          this.checkForInternetSpeed();
          this.currentStep = 2;
        })
        .catch((err) => {
          this.fixSettings = true;
          try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.prerequisitePage.audioPermissionFailed };
            data.onPage = "Prerequisites"
            data.currentProcess = "Customer failed to provide audio access permission"
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch (error){
            console.log(error);
          }
          this.isFailure = true;
          console.log(err);
          this.isAudioEnabled = false;
        });
    },
    async checkForInternetSpeed() {
      try {
        this.instructionWrapper.text = "Checking your internet speed";
         this.isNetworkFast = true;
        this.currentStep = 3;
        this.checkForVPN();
        //commented as speed test is cloaking the network in chrome, to be cleaned up later
        /*calculateSpeed('EU', (res) => {
          try {
            if (res && res.status && res.status === "failed") {
              console.log("Internet speed failed");
               try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.onPage = "Prerequisites"
                  data.status = "Dropped"
                  data.subStatus = "dropped due to failure of capturing Internet Speed"
                  data.currentProcess = "Failed to capture internet speed";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err){
                  console.log(err);
                }
              this.isNetworkFast = true;
              this.checkForVPN();
              this.currentStep = 3;
            } else {
              if (parseInt(res.speedKbps) > parseInt(this.netSpeedThreshold)) {
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.endUserInternetSpeed = res.speedKbps/1024;
                  data.onPage = "Prerequisites"
                  data.currentProcess = "internet speed captured";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err){
                  console.log(err);
                }
                this.isNetworkFast = true;
                this.currentStep = 3;
                this.checkForVPN();
              } else {
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.endUserInternetSpeed = res.speedKbps/1024;
                  data.onPage = "Prerequisites"
                  data.currentProcess = "internet speed captured, slow then threshold";
                  data.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,data);
                } catch(err){
                  console.log(err);
                }
                this.isFailure = true;
                this.isNetworkFast = false;
                this.instructionWrapper.text = "Please try again, internet is slow!";
              }
            }
          } catch(err) {
            console.log("Something went wrong", err);
            try{
              let data = {};
              let initiationId = sessionStorage.getItem('initiationId');
              data.status = "Dropped";
              data.currentProcess = "internet speed checking failed";
              data.subStatus = "Dropped on prerequisite page";
              data.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,data);
            }
            catch(err){
              console.log(err);
            }
            this.isNetworkFast = true;
            this.checkForVPN();
            this.currentStep = 3;
          }
        })*/
      } catch (error) {
        console.log("Error in internet speed checking", error);
          try{
            let data = {  ...this.logObject.prerequisitePage.dropped };
            let initiationId = sessionStorage.getItem('initiationId');
            data.currentProcess = "internet speed checking failed";
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          }
          catch(err){
            console.log(err);
          }

        this.isNetworkFast = true;
        this.checkForVPN();
        this.currentStep = 3;
      }
    },
    async checkForVPN() {
      this.showCheckingLoader();
      this.isFailure = false;
      this.vpnCheckFailed = false;
      this.instructionWrapper.text = "Checking if device is behind a VPN or Proxy.";
      eventBus.$emit("vueLoader", true);
      console.log("disable vpn check ", this.$store.getters.disableVPNCheck)
      if (this.$store.getters.disableVPNCheck) {
        this.isNetworkGood = true;
        this.vpnCheckFailed = false;
        this.captureGeoLocation();
        this.currentStep = 4;
      } else {
          try {
              let ipData = await axiosLib.get(this.$store.getters.envValues?.ipFinder || vcip_end_points.ipFinder);
              if(!ipData.data){
                throw new Error("Forencis error")
              }
              let encryptedIPAddress=encrypt(ipData.data.ip_addr, this.$store.getters.envValues.otpEncKey)

              let encryForensicsData = (await axiosLib.post(vcip_end_points.forensics,{ip:encryptedIPAddress, checkProbe:!this.$store.getters.disableVPNCheck , checkInfo:this.$store.getters.enableIpInfo}))

              let decryptedData=decrypt(encryForensicsData.data, this.$store.getters.envValues.otpEncKey);
              // console.log("decryptedData",decryptedData)

              let forensicsData;
              try {
                 forensicsData = JSON.parse(decryptedData)
              } catch (err) {
                console.log("forensics JSON error",err)
              }

              try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let data = {};
                  data.forensics = forensicsData.data;
                  data.currentProcess = "Vpn Forensics passed";
                  data.callId = this.requestId ? this.requestId : "";
                  data.vpnDetected = false;
                  this.$store.commit("setEndUserData", ["forensicsData", forensicsData.data]);
                  logReportData(this.socket, initiationId, data);
              } catch (err) {
                  console.log(err);
              }

              // console.log("forensicsData.data",forensicsData)
              if (forensicsData?.data &&
                  this.countryWhitelisting &&
                  !this.allowedCountries.includes(forensicsData.data.country_code)
              ) {
                  console.log("Country Name Recevied as:::::::", forensicsData.data.country_name);
                  if (forensicsData.data.country_name && forensicsData.data.country_name.length > 2 && !forensicsData.data.country_name.includes("?")) {
                      this.countryNameAvailable = true;
                  }
                  this.countryName = this.toTitleCase(forensicsData.data.country_name);
                  this.notAllowedCountry = true;
              } else {
                  if (forensicsData?.data?.block == 0) {
                      this.isNetworkGood = true;
                      this.vpnCheckFailed = false;
                      this.captureGeoLocation();
                      this.currentStep = 4;
                  } else {
                      this.isFailure = true;
                      this.isNetworkGood = false;
                      this.vpnCheckFailed = true;
                      let data = { ...this.logObject.prerequisitePage.vpnCheckFailed };
                      let initiationId = sessionStorage.getItem('initiationId');
                      data.currentProcess = "Vpn check failed";
                      data.callId = this.requestId ? this.requestId : "";
                      data.vpnDetected = true;
                      logReportData(this.socket, initiationId, data);
                  }
              }
          } catch (err) {
              console.log("Could not check ipData", err);
              try {
                  let data = { ...this.logObject.prerequisitePage.vpnCheckFailed };
                  let initiationId = sessionStorage.getItem('initiationId');
                  data.currentProcess = "Vpn check failed";
                  data.callId = this.requestId ? this.requestId : "";
                  data.vpnDetected = true;
                  logReportData(this.socket, initiationId, data);
              } catch (err) {
                  console.log(err);
              }
              this.isFailure = true;
              this.isNetworkGood = false;
          }
      }
      eventBus.$emit("vueLoader", false);
    },
    async captureGeoLocation() {
      this.instructionWrapper.text ="Fetching your location...";
      try{
        let data = await getPosition();
        console.log(data);
        //if country whitelisting is enabled, do geolocation country whitelist here
        if(this.countryWhitelisting){
          let geoCheckData = {
            lat:data.coords.latitude,
            lon:data.coords.longitude
          }
          await this.getLocationData(geoCheckData);
        }
        if(this.geoCountryCheckPass){
            this.$store.commit("setEndUserData", [
              "locationData",
              {
                lat: data.coords.latitude,
                lon: data.coords.longitude,
              },
            ]);
            localStorage.setItem('location_lat', data.coords.latitude);
            localStorage.setItem('location_lon', data.coords.longitude);
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let formdata = {};
              formdata.geoData = {};
              formdata.geoData.latitude = data.coords.latitude;
              formdata.geoData.longitude = data.coords.longitude;
              formdata.currentProcess = "geo location fetched passed";
              formdata.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,formdata);
            } catch(err){
              console.log(err);
            }
            this.isGeolocation = true;
            this.instructionWrapper.text = "Congrats, we are all set to go!";
            this.instructionWrapper.status = "success";
            this.currentStep = 5;
        }
      }
      catch(err){
          this.fixSettings = true;
          this.isFailure = true;
          this.instructionWrapper.text ="Can't access your location. Allow geolocation from settings and Retry.";
          console.log("GEO Location Error::::", err);
          try {
            let initiationId = sessionStorage.getItem('initiationId');
            let data = { ...this.logObject.prerequisitePage.locationDropped };
            data.currentProcess = err;
            data.callId = this.requestId ? this.requestId : "";
            logReportData(this.socket,initiationId,data);
          } catch(err){
            console.log(err);
          }
      }
    },

    async showCheckingLoader() {
      this.isCheckingVPN = true; 
      await delay(10000);
      this.isCheckingVPN = false;
    },

    async checkVPNConnectionRepeatedly() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.isCheckingVPN = true;
      this.showCheckingLoader();

      this.intervalId = setInterval(async () => {
        try {
          let forensicsData = await this.isVPNConnected(this.requestId);
          if(forensicsData.data.block === 0) {
            this.isVPNDisconnected = true;
            this.vpnCheckFailed = false;
            clearInterval(this.intervalId);
          } else {
            this.isVPNDisconnected = false;
            this.vpnCheckFailed = true;
            sessionStorage.setItem("vpnChecker", parseInt(sessionStorage.getItem("vpnChecker") ?? 0) + 1);
          }
        } catch (error) {
          console.error("Error checking VPN connection:", error);
          clearInterval(this.intervalId);
        }
      }, 5000);
    },

    async isVPNConnected (requestId) {
      let ipData = await axiosLib.get(this.$store.getters.envValues?.ipFinder || vcip_end_points.ipFinder);
      if (!ipData.data) {
          throw new Error("Forencis error")
      }
      let encryptedIPAddress = encrypt(ipData.data.ip_addr, this.$store.getters.envValues.otpEncKey)
      let encryForensicsData = (await axiosLib.post(vcip_end_points.forensics, { ip: encryptedIPAddress, checkProbe:!this.$store.getters.disableVPNCheck , checkInfo:this.$store.getters.enableIpInfo }))
      let decryptedData = decrypt(encryForensicsData.data, this.$store.getters.envValues.otpEncKey);
      let forensicsData;
      try {
          forensicsData = JSON.parse(decryptedData)
      } catch (err) {
          console.log("forensics JSON error", err)
      }
      try {
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {};
          data.forensics = forensicsData.data;
          data.currentProcess = "Vpn Forensics passed";
          data.callId = requestId ? requestId : "";
          this.$store.commit("setEndUserData", ["forensicsData", forensicsData.data]);
          logReportData(this.socket, initiationId, data);
      } catch (err) {
          console.log(err);
      }
      return forensicsData;
    },
  },

  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  computed: {
    isAllowedToMove() {
      return Boolean(
        this.isVideoEnabled &&
          this.isAudioEnabled &&
          this.isNetworkGood &&
          this.isNetworkFast &&
          this.isGeolocation
      );
    },
  },
  watch:{
    currentStep(value){
      if(value == 5){
        this.$store.commit('setAllowUserInQueue', true);
      }
    }
  },
  props: ["requestId", "callData", "sessionId", "socket"],
};
</script>

<style scoped>
.salutation {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 3vh;
  line-height: 1em;
  color: black !important;
}
.instructionText {
  font-size: 16px;
}
.feedback {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.permissionProgress {
  position: fixed;
  color: white;
}
.videoGrad {
  background: transparent
    linear-gradient(180deg, #00000000 0%, #00000004 26%, #000000 100%) 0% 0%
    no-repeat padding-box;
}
#videoGradient {
  position: fixed;
}
#reflection {
  width: 100%;
  background: black;
}
.customBtn {
  width: 200px;
}
.instructionWrapperError{
  font-weight: 600;
  font-size: 1.4em;
  color: #CC0000;
  padding-left: 3em;
  padding-right: 3em;
}
.instructionWrapperSuccess{
  color: #3DBE29;
}
.bold{
  font-weight: 600;
}
</style>
